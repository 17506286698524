import React from "react";
import {Link} from "gatsby";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, Navigation, EffectFade} from "swiper";
import {Col, Container, Row} from "react-bootstrap";
import {SliderTwoData} from "@/data";

import Vid from "../assets/video/baldurs.webm"

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const BaldursGateVideo = (data) => {

    return (
        <section className="service_section">
            <div className="image-layer rounded-video">
                <video playsInline={true} autoPlay={true} muted={true} loop={true}>
                    <source type={"video/webm"} src={Vid}>
                    </source>
                </video>
            </div>
        </section>

    );
};

export default BaldursGateVideo;
