import React from "react";
import {Link} from "gatsby";
import {SliderThreeData} from "../data";


//Meow swiper
import {Swiper, SwiperSlide} from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const BaldursGateCarousel = (data) => {
    const mainSlideOptions = {
        slidesPerView: 1,
        loop: true,
        effect: "fade",
        navigation: {
            nextEl: "#main-slider-next",
            prevEl: "#main-slider-prev",
        },
        allowSlideNext: false,
        allowSlidePrev: false,
        zoom: true,
        autoplay: {
            delay: 500,
        },
        slidesPerGroupSkip: 1
    };

    return (
        <section className="service_section  baldursCarousel main-slider main-slider__two ">
            <Swiper
                modules={[Autoplay, Pagination, Navigation]}
                effect="slide"
                speed={1000}
                spaceBetween={0}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={{
                }}
                className="mySwiper"

            >
                {SliderThreeData.map(({image, subTitle, title, button}, index) => (
                    <SwiperSlide key={index}>
                        <div className={"baldursCarouselContainer"}>
                            <img alt="img" src={`${image}`}></img>
                        </div>
                    </SwiperSlide>
                ))}

            </Swiper>


        </section>

    );
};

export default BaldursGateCarousel;
