import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";

import HeaderOne from "@/components/header-one";
import SEO from "@/components/seo";
import PageBanner from "../../components/page-banner";
import {Col, Container, Row} from "react-bootstrap";
import ContactForm from "../../components/contact-form";
import {graphql} from "gatsby";
import SliderTwo from "../../components/slider-two";
import HomePageVideo from "../../components/home-page-video";
import BaldursGateCarousel from "../../components/baldurs-gate-carousel";
import BaldursGateVideo from "../../components/baldurs-gate-video";

const BaldursGate = (data) => {
    return (
        <MenuContextProvider>
            <SearchContextProvider>
                <SEO title="Neemu - VFX Studio"/>
                <Layout>
                    <HeaderOne/>

                    <PageBanner></PageBanner>
                    <section className="service_section commonSection">
                        <Container>
                            <Row>
                                <Col lg={12} className="text-center">
                                    <h2 className="sec_title white">{"Baldur's Gate 3"}</h2>
                                    <div className={"maxWidthProject"}>
                                        <p className="sec_desc color_aaa">
                                            It's a great honor that Larian Studio has selected Neemu Studio as their external VFX
                                            development partner for a narrative-driven, party-based RPG within the iconic Dungeons
                                            & Dragons universe: Baldur's Gate 3.
                                        </p>
                                        <p className="sec_desc color_aaa">
                                            With its strategic gameplay and immersive fantasy realms,Baldur's Gate 3 offers an
                                            unmissable experience. It’s not just a game, it’s an odyssey through a world of magic,
                                            monsters, and moral dilemmas.
                                        </p>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>


                    <BaldursGateVideo {...data}/>


                    <section className="service_section commonSection">
                        <Container>
                            <Row>
                                <Col lg={12} className="text-center">
                                    <h2 className="sec_title white">{"Our work"}</h2>
                                    <div className={"maxWidthProject"}>
                                        <p className="sec_desc color_aaa">
                                            Neemu studio was involved in visual effects’ development for characters, items,
                                            different creatures, weapons and environment. The team had a wonderful chance to
                                            create VFX using a custom engine and refine their visual styles by following the
                                            guidance of the top-level artists.
                                        </p>
                                        <p className="sec_desc color_aaa">
                                            Having the majority of our artists engaged in Baldur's Gate 3, we've formulated and
                                            set forth fresh benchmarks for visual coherence and narrative delivery,which we
                                            subsequently applied in collaboration with other gaming projects.
                                        </p>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>


                    <BaldursGateCarousel {...data}></BaldursGateCarousel>

                    <section className={""}>
                        <Container>
                            <Row>
                                    <div className={"maxWidthProject"}>
                                        <p className="sec_desc color_aaa">
                                            ©2023 Wizards of the Coast and Larian Studios
                                        </p>
                                    </div>
                            </Row>
                        </Container>

                    </section>

                    <ContactForm/>

                    <Footer/>
                </Layout>
            </SearchContextProvider>
        </MenuContextProvider>
    );
};

export default BaldursGate;


export const query = graphql`
query HeroQuery {
  allStrapiHero {
    nodes {
      heading
      description
      video {
        mime
        localFile { 
          publicURL
        }
      }
    }
  }
}
`
